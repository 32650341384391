import React from 'react';
import './file-list.css';
import { Image } from '../utils/carousel.utils'
import {
  Box,
  Text,
  MantineProvider,
} from "@mantine/core";

import {
  IconTrash,
  IconPaperclip
} from "@tabler/icons-react";
import { isUUID } from '../../utils/file.utils';

export interface FileListProps {
  files: Image[],
  deleteOneFile: (id: string, file: string) => void,
  downloadOneFile: (key: string) => void,
  refetch?: () => void,
}

export const FileList = ({
  ...props
}: FileListProps) => {
  const isFileImage = (key: string) => {
    const img = key.split('.');
    return ["jpg", "jpeg", "gif", "png", "piff", "bmp"].includes(img[img.length - 1])
  }

  return (
    <MantineProvider>
      <Text>Fichiers ajoutés:</Text>
      <Box>
        {
          props.files.toReversed().map(file => (
            <Box
              key={file.id}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                onClick={() => props.downloadOneFile(file.key)}
                style={{ cursor: "pointer", width: "max-content", display: "flex",margin: "5px 0" }}
              >
                {file.path &&
                  <div className="icon-style" style={!isFileImage(file.key) ? {
                    display: "inline-table"
                  } : {}}>
                    {isFileImage(file.key) &&
                      <img
                        src={file.path}
                      />
                    }
                    {!isFileImage(file.key) &&
                      <p style={{ width: "30px", margin: "0", display: "flex" }}>
                        <IconPaperclip style={{margin:"auto"}} />
                      </p>
                    }
                  </div>
                }
                <div style={{ display: "flex" }}>
                  <span style={{ margin: "auto" }}>
                    {(isUUID(file.key))? file.key.substring(37) : file.key}
                  </span>
                </div>
              </Text>
              { file.id && file.key &&
                <Box style={{ cursor: "pointer", display: "flex" }}>
                  <IconTrash
                    style={{ margin: 'auto' }}
                    onClick={async () => {
                      await props.deleteOneFile(file.id, file.key);
                      if(props.refetch) {
                        props.refetch()
                      }
                    }}
                  />
                </Box>
              }
            </Box>
          ))
        }
      </Box>
    </MantineProvider>
  )
}
