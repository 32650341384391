import React, { FC, useEffect, useRef, useState } from "react";
import {getDomain, useFiles} from "@jasper/shared";
import { useParams } from "react-router-dom";
import {
  Box,
} from "@mantine/core";
import Logo from "/@/assets/images/logo.svg";
import { FileType } from "/@/generated/graphql";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import {Carousel} from "@jasper/shared/stories/Carousel/Carousel";
import {Image} from "@jasper/shared/stories/utils/carousel.utils";
import { io } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";
import { ButtonFileInput } from "@jasper/shared/stories/ButtonFileInput/ButtonFileInput";
import { FileList } from "@jasper/shared/stories/FileList/FileList";

export const UploadFilePage: FC = () => {
  const [imageList, setImageList] = useState<Image[]>([]);
  const [files, setFiles] = useState([]);
  const param = useParams();
  const imgCount = useRef(0);
  Cookies.set("Authentication", param.token,{
    expires: 1,
    domain: getDomain(import.meta.env.MODE),
  });

  if (param.id === undefined || param.name === undefined || param.token === undefined) {
    return;
  }

  const {
    filesOfOrder,
    uploadFileToS3,
    getPresignedDownloadUrl,
    deleteOneFile,
    getFileUrl,
    refetchFilesOfOrder,
  } = useFiles(param.id);

  const refreshImageList = async (data: any) => {
    return await Promise.all(
      data.map(async (obj:Image) => {
        const path = await getFileUrl(obj.key);
        return {
          ...obj,
          path: path,
        };
      }),
    );
  };

  useEffect(() => {
    posthog.capture("user_in_fileupload_page");
  }, []);
  useEffect(() => {
    const f = filesOfOrder?.getFilesOfOrder.filter(file => file.fileType === FileType.ClientFile)
    imgCount.current = (f ?? []).length ?? 0;
    setFiles(f)
    if(f) {
      refreshImageList(f).then((a)=> setImageList(a));
    }
  }, [filesOfOrder?.getFilesOfOrder]);

  useEffect(() => {
    setImageList([]);
  }, [param.id]);

  useEffect(() => {
    const socket = io(getApiUrl(),
    {
      query: {
        type: "orderFile",
        order_id: param.id,
      },
    });

    socket.connect();
    socket.on("clientOrderFile", () => {
      refetchFilesOfOrder();
    });
    return () => {
      socket.disconnect();
      socket.off("connect");
      socket.off("disconnect");
      socket.off("clientOrderFile");
    };
  }, []);

  return (
    <>
      <div style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        backgroundColor: "#5172E6",
        padding: "20px 0",
      }}>
        <div style={{
          width: "80%",
          minHeight: "60%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
        }}>
          <img
            src={Logo}
            style={{ height: "auto", margin: "20px auto" }}
          />
          <p>Nom du patient : {param.name}</p>
          <ButtonFileInput
            onFilesAdd={files => {
              const fileList = [...files].map(file =>
                {
                  const name = file.name.split('.')
                  return new File([file], `photo_${(imageList.length + 1)}.${name[name.length - 1]}`, {type: file.type})
                }
              );
              uploadFileToS3(fileList, FileType.ClientFile);
            }}
            buttonText={(<span>Prendre une photo ou<br/>choisir dans la photothèque</span>)}
            accept="image/*"
          />
          <div style={{ padding: "20px" }}>
            <Carousel imageList={imageList}/>
          </div>
          {files && files.length > 0 && (
            <Box>
              <FileList
                files={files}
                deleteOneFile={deleteOneFile}
                downloadOneFile={getPresignedDownloadUrl}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};
