import React, {useEffect, useState} from "react";
import {RoutesDispatcher} from "/@/routes/RoutesDispatcher";
import {api} from "./services/api/api";
import {useAuthContext} from "./shared/contexts/AuthContext";
import "dayjs/locale/fr";
import {Crisp} from "crisp-sdk-web";
import "@mantine/dropzone/styles.css";

function App() {
  const [authInit, setAuthInit] = useState(true);
  const {user, logIn, logOut} = useAuthContext();

  const crispSDK: any = Crisp;
  crispSDK.configure("e1f9409a-4808-4c35-ace9-7842d81cad68");
  if (user?.email) {
    crispSDK.user.setEmail(user.email);
  }
  crispSDK.chat.hide();
  crispSDK.chat.onChatClosed(() => {
    crispSDK.chat.hide();
  });

  const checkIfLoggedIn = async () => {
    const user = await api.get("/users/me");
    return user;
  };

  const onFocus = () => {
    if (!checkIfLoggedIn()){
      logOut();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);

    return (() => {
      window.removeEventListener("focus", onFocus);
    });
  }, [false]);

  useEffect(() => {
    checkIfLoggedIn()
      .then(res => {
        Crisp.user.setEmail(res.data.email);
        logIn(res.data);
      })
      .catch(res => {
        // ts-ignore

        if (res.data) {
          Crisp.session.reset();
        }
        logOut();
      })
      .finally(() => {
        setAuthInit(false);
      });
  }, []);

  return !authInit ? (
    <>
      <RoutesDispatcher />
    </>
  ) : null;
}

export default App;
